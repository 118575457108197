import scrollIntoView from "scroll-into-view";

export default class ScrollHandler {
  static ScrollIntoView(
    element: HTMLElement,
    dockToTop = false,
    withOffset = false,
    time = 500,
    callback = undefined,
  ) {
    //Keep clearing timeout until it has stopped scrolling
    const navigationContainer = $(".navigation-container");
    const topOffset =
      navigationContainer.length > 0
        ? navigationContainer.find(".top-menu").first().height()
        : 0;
    scrollIntoView(
      element,
      {
        time: time,
        align: {
          top: dockToTop ? 0 : 0.5,
          topOffset: withOffset ? topOffset : 0,
        },
        cancellable: false,
        maxSynchronousAlignments: 1,
      },
      callback,
    );
    if ($(element).hasClass("construction-window")) {
      const newEvent = new CustomEvent("update-world-position");
      element.dispatchEvent(newEvent);
    }
  }
}
